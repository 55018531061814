<template>
  <div class="thnx">
    <div class="container d-flex align-items-center">
      <div class="row">
        <div class="col-12 col-md-6 d-flex align-items-center">
          <div class="content w-100">
            <div class="title pb-sm-4">
              <h4>Gelukt!</h4>
            </div>
            
            <p class="text pb-4">
              We hebben je gegevens ontvangen en nemen binnen één week contact op
              om de vervolgstappen te bespreken.
            </p>
          </div>
        </div>

        <div class="col-12 col-md-6 d-flex align-items-center">
          <div class="card card__steplist w-100 py-4 px-5">
            <stepList :amoundDone="2"></stepList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import stepList from '@/components/step_list.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Thnx',
  components: {
    stepList,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getClubData']),
  },
};
</script>
